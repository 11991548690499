/* Google fonts Pacifico */
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500;700&display=swap');

/* Billboard */
.parallax-bg {
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: background 1s;
}
.top {
  height: calc(100vh - 64px);
}

.pacific {
  font-family: 'Pacifico', 'M PLUS Rounded 1c', sans-serif;
}
.pacific-rotate {
  font-family: 'Pacifico', 'M PLUS Rounded 1c', sans-serif;
  font-weight: 500 !important;
  line-height: 1.05;
  transform: rotate(-5deg);
}
.big-text {
  font-size: 5rem;
  font-weight: normal;
}
/*
.big-text::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -15px;
  display: inline-block;
  width: 50px;
  height: 5px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: white;
  border-radius: 2px;
}
*/
.semi-big-text {
  font-size: 2rem;
  font-weight: normal;
  margin-top: 2rem;
}
.txt-center {
  text-align: center;
}
.c-title {
  background: #fff;
  background: var(--theme-content-section-background-color);
  padding: 1rem;
}
.center-text-box {
  max-width: 720px;
  text-align: left;
  margin: 1rem auto;
  white-space: pre-line;
}

@media screen and (max-width: 767px) {
  .top {
    height: calc(100vh - 56px);
  }
  .top__body {
    margin-top: -100px;
  }
  .prev-next-btn {
    z-index: 3;
  }

  /* Billboard */
  .big-text {
    font-size: 3rem;
    font-weight: normal;
  }
  /* /Billboard */

  .center-text-box {
    max-width: 350px;
  }
}

/* For touchscreens, scroll image since iOS doesn't recognize "background-attachment: fixed;" */
@media (hover: none) {
  .parallax-bg {
    background-attachment: scroll;
  }
}
